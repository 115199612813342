import React from "react"
import GlobalStyle from "../components/GlobalStyle"
import MenuBar from "../components/layout/MenuBar"
import BackgroundImage from "gatsby-background-image"
import DatePickerComponent from "../components/datePickerComponent/DatePickerComponent"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import ResultCard from "../components/ResultComponents/ResultCard"
import { graphql } from "gatsby"
import { useCabins } from "../hooks/useCabins"
import { device } from "../utils/mediaQueries"
import { Breadcrumbs } from "../components/layout/breadcrumbs/Breadcrumbs"
import { CityCategories } from "../components/cityCategories/CityCategories"

const Box = styled.div`
  margin: 50px;
  border-radius: 15px;
  padding: 0px;
  text-align: left;
  @media ${device.mobileL} {
    margin: 5px;
  }
`

const OtherCitiesContainer = styled.div`
  margin: 4rem;
  padding: 4rem;
  @media ${device.mobileL} {
    margin: 0.5rem;
    padding: 0.5rem;
  }
`

const HeaderContainer = styled.div`
  padding: 0rem 1rem;
`

const Background = styled(BackgroundImage)`
  margin-bottom: 30px;
  padding-bottom: 30px;
`

const Header1 = styled.h1`
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  padding: 5px 20px;

  color: white;
  @media ${device.mobileL} {
    padding: 0.1rem;
    font-size: 35px;
  }
`
const Header2 = styled.h2`
  font-size: 27px;
  text-align: center;
  font-weight: 400;
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding: 5px 20px;
  color: white;
`

const Header3 = styled.h3`
  font-size: 20px;
  text-align: center;
  font-weight: 200;

  padding: 5px 20px;
  color: black;
`

const ResultContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 400px));
  padding: 50px 150px;
  grid-gap: 15px;
  @media ${device.mobileL} {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 2px;
  }
`

const TextContainer = styled.div`
  padding: 50px 150px;
  @media ${device.mobileL} {
    padding: 15px;
  }
`

const Template = ({ data }) => {
  const imageData = data.file.childImageSharp.fluid
  const postData = data.markdownRemark.frontmatter
  const cabinsHook = useCabins(data.allMokitJson.nodes)
  const products = cabinsHook.originalCabins
  const cities = data.cities.edges.filter(
    city => city.node.frontmatter.name !== data.markdownRemark.frontmatter.name
  )
  const bulkCities = data.bulkCities.edges
  return (
    <GlobalStyle>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.markdownRemark.frontmatter.metaTitle}</title>
        <meta
          name="description"
          content={data.markdownRemark.frontmatter.metaDesc}
        />
        <html lang="fi" />
      </Helmet>
      <Background Tag="section" fluid={imageData} backgroundColor={`#040e18`}>
        <MenuBar items={data.allMarkdownRemark.edges} />
        <HeaderContainer>
          <Box>
            <Header1>{postData.title}</Header1>
            <Header2>Etsi sopiva majoitus alapuolelta</Header2>
            {/* <FilterContainer>
              <CabinFilter
                title={"Montako henkilöä mökissä majoittuu?"}
                placeholder={"henkilöä"}
                action={cabinsHook.setPersons}
              />
            </FilterContainer> */}
            <DatePickerComponent
              urlFix={data.markdownRemark.frontmatter.urlFix}
              filter={data.markdownRemark.frontmatter.filter}
              name={data.markdownRemark.frontmatter.name}
              isRegion={false}
              bookingId={postData.bookingId}
            />
          </Box>
          <div></div>
        </HeaderContainer>
      </Background>
      <Breadcrumbs />

      <ResultContainer>
        {products.map((p, i) => {
          return (
            <ResultCard
              key={i}
              name={p.Name}
              price={p.fields.price}
              img={p.localFile.childImageSharp.fluid}
              address={p.fields.address}
              city={p.fields.city}
              people={p.fields.people}
              url={p.TrackingUrl}
            />
          )
        })}
      </ResultContainer>
      {bulkCities.length > 0 && (
        <>
          <Header3>Löydät muunmuassa nämä paikat majoittumiseen</Header3>
          <CityCategories cities={bulkCities} bulk={true} />
        </>
      )}
      <TextContainer
        dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
      ></TextContainer>
      <Header3>Voit myös varata mökin seuraavista kohteissa</Header3>
      <OtherCitiesContainer>
        <CityCategories cities={cities} />
      </OtherCitiesContainer>
    </GlobalStyle>
  )
}
export const query = graphql`
  query($filter: String!, $path: String!, $img: String!, $city: String!) {
    file(base: { eq: $img }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMokitJson(filter: { fields: { city: { eq: $filter } } }) {
      nodes {
        fields {
          city
          address
          people
          size
          price
        }
        Name
        TrackingUrl
        id
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        filter
        img
        metaDesc
        metaTitle
        path
        name
        title
        bookingId
        urlFix
      }
    }
    cities: allMarkdownRemark(
      filter: { frontmatter: { parent: { eq: $filter } } }
    ) {
      edges {
        node {
          frontmatter {
            path
            filter
            name
            img
          }
        }
      }
    }
    bulkCities: allMongodbMokkivuokrausCities(
      filter: { parentCity: { eq: $city } }
    ) {
      edges {
        node {
          city
          parentCity
          region
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "city" } } }
      sort: { fields: frontmatter___name }
    ) {
      edges {
        node {
          frontmatter {
            filter
            path
            name
          }
        }
      }
    }
  }
`
export default Template
