import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { device } from "../../utils/mediaQueries"

const Container = styled.div`
 display: grid;
 grid-template-rows: 50% 50%;
  box-shadow: 0 0 1px 1px rgba(20, 23, 28, 0.1),
    0 3px 1px 0 rgba(20, 23, 28, 0.1);
  border-radius: 5px;
  @media ${device.mobileL} {
    margin: 5px;
  }
`

const ProductImg = styled(Img)`
  width: 100%;
  height: 100%;
  z-index: -1;
`

const InfoContainer = styled.div`
  padding: 20px;
  display: grid;
  grid-template-rows: repeat(4,1fr);
  grid-gap: 10px;
`

const Name = styled.h3`
  font-size: 22px;
  font-weight: 600;
`

const Price = styled.div`
  justify-content: flex-end;
`

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Persons = styled.div``

const Address = styled.div`
  font-size: 16px;
  font-weight: 100;
  text-transform: capitalize;
`

const City = styled.div`
  font-weight: 800;
`
const Button = styled.div`
  padding: 10px 15px;
  background-color: #f33636;
  color: white;
  font-size: 17px;
  border-radius: 5px;
  margin-right: 20px;
`

const ResultCard = props => {
  return (
    <Container>
      <ProductImg fluid={props.img} />
      <InfoContainer>
        <Name>{props.name}</Name>
        <Address>
          {props.address}
          <City>{props.city}</City>
        </Address>
        <Persons>
          <span>Henkilömäärä: </span>
          {props.people}
        </Persons>
        <ActionContainer>
          <a href={`/varaa/${props.name.toLowerCase()}`} rel="nofollow" target="_blank noopener">
            <Button>Tarkista saatavuus</Button>
          </a>
          <Price>
            <span>Alkaen </span>
            {props.price}€/viikko
          </Price>
        </ActionContainer>
      </InfoContainer>
    </Container>
  )
}

export default ResultCard
