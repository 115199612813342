import { useState, useEffect, useCallback } from "react"

export const useCabins = cabins => {
  const [originalCabins] = useState(cabins)
  const [filteredCabins, setFilteredCabins] = useState(originalCabins)
  const [filterPrice, setFilterPrice] = useState(9999)
  const [filterPersons, setFilterPersons] = useState(-1)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false)
    }
  }, [isLoading])

  const filterCabins = useCallback(() => {
    setIsLoading(true)
    const cabins = originalCabins.filter(
      c => c.fields.price <= filterPrice && c.fields.people >= filterPersons
    )
    setFilteredCabins(cabins)
  }, [filterPrice, filterPersons, originalCabins])

  const setPrice = f => {
    setFilterPrice(f)
  }

  const setPersons = f => {
    setFilterPersons(f)
  }

  const resetCabins = () => {
    setFilteredCabins(originalCabins)
  }

  const reset = () => {
    setIsLoading(true)
  }

  return {
    originalCabins,
    isLoading,
    setPrice,
    setPersons,
    filterCabins,
    reset,
    resetCabins,
  }
}
